import React, { useEffect } from 'react';
import LowPriceFlights from '@ctrip/flt-smart-component-pc/lowPriceFlights';
import { logTraceByKeyName } from '@ctrip/xproduct-library-h5/lib/Tracking';
import { UBT_KEY } from '@/src/constants/ubtKeys';
import LowPriceHeader from '../LowPriceHeader';

export default function LowPriceReportContainerV2(props) {
	const { dCity } = props;

	useEffect(() => {
		logTraceByKeyName(UBT_KEY.HOME.REPORT_CITY_SHOW, '');
	}, []);
	return (
		<LowPriceHeader
			dCity={dCity}
			cityClickKey={UBT_KEY.HOME.REPORT_CITY_CLICK}
			channel="online_theme"
			jumpMore={() => {
				logTraceByKeyName(UBT_KEY.HOME.REPORT_MORE_CLICK, '');
			}}
			title="低价速报"
		>
			{(departCityCode) => <LowPriceFlights departCityCode={departCityCode} />}
		</LowPriceHeader>
	);
}
